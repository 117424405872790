<template>
  <a-col span="24">
    <a-row>
      <a-col :span="isDesktop ? 12 : 24">
        <div :class="$style.left">
          <a-row>
            <a-col span="24">
              <div v-if="isMobile" :class="$style.logo">
                <img
                  src="https://travelfy.knowbit.tech/wp-content/uploads/2021/07/branco-logo-nova-1024x512.png"
                  width="300"
                  alt=""
                />
              </div>
              <div :class="$style.welcome">Solicitação de nova senha</div>
            </a-col>
            <a-col span="24">
              <a-form
                layout="vertical"
                :model="formState"
                @finish="handleFinish"
                @finishFailed="handleFinishFailed"
              >
                <a-form-item
                  :rules="{
                    required: true,
                    type: 'email',
                    message: 'E-mail invalido!',
                    trigger: 'change',
                  }"
                >
                  <a-input
                    :class="$style.rounded"
                    v-model:value="formState.email"
                    placeholder="Endereço de e-mail"
                    size="large"
                  >
                    <template #prefix
                      ><UserOutlined style="color: rgba(0, 0, 0, 0.25)"
                    /></template>
                  </a-input>
                </a-form-item>
                <a-form-item>
                  <a-button
                    block
                    :class="$style.login"
                    type="primary"
                    html-type="submit"
                    shape="round"
                    size="large"
                    :loading="isLoading"
                    :disabled="formState.email === ''"
                  >
                    <template #icon>
                      <SendOutlined />
                      Enviar
                    </template>
                  </a-button>
                </a-form-item>
              </a-form>
            </a-col>
            <a-col span="24">
              <div :class="$style.wrapperrecover">
                <a href="/login" :class="$style.returnLogin">
                  Retornar ao login
                </a>
              </div>
            </a-col>
          </a-row>
        </div>
      </a-col>
      <a-col v-if="isDesktop" span="12">
        <div :class="$style.right">
          <img
            src="https://travelfy.knowbit.tech/wp-content/uploads/2021/07/branco-logo-nova-1024x512.png"
            width="300"
            alt=""
          />
        </div>
      </a-col>
    </a-row>
  </a-col>
</template>

<script>
import { UserOutlined, SendOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { defineComponent, reactive } from "vue";
import { mapActions, mapState } from "vuex";
import { isDesktop, isMobile } from "@/utils/isAgent";
export default defineComponent({
  setup() {
    const formState = reactive({
      email: "",
    });

    return {
      formState,
      isDesktop,
      isMobile,
    };
  },
  components: {
    UserOutlined,
    SendOutlined,
  },
  computed: {
    ...mapState("user", ["isLoading"]),
  },
  methods: {
    ...mapActions("user", ["recoverPassword"]),

    async handleFinish() {
      try {
        const data = {
          email: this.formState.email,
        };
        await this.recoverPassword(data);
        message.success(
          "Senha solicitada com sucesso! Um e-mail será enviado com a nova senha."
        );
        this.formState.email = "";
      } catch (e) {
        message.error(
          "Não foi possivel solicitar a senha! Por favor, verifique o e-mail e tente novamente."
        );
      }
    },
  },
});
</script>

<style module src="./style.scss" lang="scss" />
